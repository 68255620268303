import AuthControl from "@/components/AuthControl";
import AuthMenu from "@/components/AuthMenu";
import MenuToggle from "@/components/MenuToggle";
import MobLogotype from "@/components/MobLogotype";
import SearchBar from "@/components/SearchBar";
import SearchMobileFilterToggle from "@/components/SearchMobileFilterToggle";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useMenuContext } from "@/contexts/MenuContext";
import { NotificationsContext } from "@/contexts/NotificationsContext";
import { useSearchContext } from "@/contexts/SearchContext";
import {
  joinButtonClicked,
  logoClicked,
  searchBarFocused,
  searchBarQuerySubmitted,
} from "@/gtmEvents/globalHeader";
import useCurrentUser from "@/hooks/useCurrentUser";
import pushToDataLayer from "@/lib/pushToDataLayer";
import NavBarDesktop from "@/src/homepage/components/NavBarDesktop";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import NavBarMobile from "@/src/homepage/components/NavBarMobile";
import Breadcrumbs from "@/src/breadcrumbs/Breadcrumbs";
import useIsHomepage from "@/hooks/useIsHomepage";

const Header = ({
  displayCurrency,
  theme = "light",
  primaryNavigation,
  navigation,
  isMobApp,
  promoBanner,
  breadcrumbs,
}) => {
  const { showNotification } = useContext(NotificationsContext);

  const { isPinned, isTop, showBorder } = useHeaderContext();

  const headerRef = useRef(null);

  useEffect(() => {
    const setHeaderHeight = () => {
      if (typeof window === "undefined" || !headerRef.current) return;

      const headerHeight = headerRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    };

    setHeaderHeight();
    window.addEventListener("resize", setHeaderHeight);

    return () => {
      window.removeEventListener("resize", setHeaderHeight);
    };
  });
  return (
    <>
      <header
        ref={headerRef}
        className={clsx(
          "Header",
          "sticky left-0 right-0 top-0 z-50 w-screen transform-gpu lg:border-b print:static print:!bg-white print:text-zinc-800",
          {
            "bg-zinc-800 text-white": theme === "dark",
            "bg-white text-zinc-800": theme === "light",
            "lg:translate-y-0 print:!translate-y-0":
              (isTop || isPinned) && !showNotification,
            "translate-y-12 print:!translate-y-0": showNotification,
            "lg:border-b-transparent": !showBorder,
            "lg:border-b-zinc-200": showBorder,
          }
        )}
      >
        <div className={clsx("Header__container", "relative z-20")}>
          <_Desktop
            currency={displayCurrency}
            theme={theme}
            isMobApp={isMobApp}
            primaryNavigation={primaryNavigation}
            promoBanner={promoBanner}
            breadcrumbs={breadcrumbs}
          />
          <_Mobile
            currency={displayCurrency}
            theme={theme}
            isMobApp={isMobApp}
            primaryNavigation={primaryNavigation}
            navigation={navigation}
          />
        </div>
        <_MobileSearchBar theme={theme} breadcrumbs={breadcrumbs} />
      </header>
    </>
  );
};

const getUpgradeUrl = (currency: string) => {
  const currentPath = window.location.pathname;
  // using includes here as path may be /premium, /premium-1 ...
  if (currentPath.includes("/premium")) {
    return "/signup";
  }
  return `/premium${currency ? `?displayCurrency=${currency}` : ""}#sign-up`;
};

const _Desktop = observer(
  ({
    currency,
    theme = "dark",
    primaryNavigation,
    promoBanner,
    breadcrumbs,
  }) => {
    const [isClient, setIsClient] = useState(false);
    const [isNavBarDesktopOpen, setIsNavBarDesktopOpen] = useState(false);
    const isHomepage = useIsHomepage();

    useEffect(() => {
      setIsClient(true);
    }, []);

    const { isLoggedOut, isLoggedIn, isFetching, currentUser } =
      useCurrentUser();
    const { isHeroScrolledPast } = useHeaderContext();

    const router = useRouter();

    const { setIsDesktopOverlayOpen } = useSearchContext();

    const isMobPlus = currentUser?.plan === 2;

    const handleFocus = () => {
      setIsDesktopOverlayOpen(router.pathname !== "/search");

      // Send an event to GTM
      pushToDataLayer(searchBarFocused);
    };

    const shouldShowPromoBanner =
      promoBanner &&
      promoBanner.whoShouldSeeThis?.includes(String(currentUser?.plan ?? 0));

    return (
      <>
        {shouldShowPromoBanner && (
          <Link href={promoBanner.target?.url}>
            <div
              className="flex w-full justify-center gap-4 py-[10px] text-[15px] text-white"
              style={{
                backgroundColor: promoBanner.colourway?.[0]?.colourPreview,
              }}
            >
              <div className="flex items-center">{promoBanner.title}</div>
            </div>
          </Link>
        )}
        <div
          className={clsx(
            "hidden h-20 grid-cols-12 items-center sm:gap-6 lg:grid"
          )}
        >
          <div
            className={clsx(
              "relative col-span-4 flex h-full items-center pl-12 print:!hidden"
            )}
          >
            <Link
              href="/"
              className={clsx("flex h-8 w-22 items-center active:scale-95")}
              aria-label="Mob"
              onClick={() => {
                pushToDataLayer(logoClicked);
              }}
            >
              <MobLogotype />
            </Link>
            <div
              className={clsx(
                "ml-6 whitespace-nowrap border-l border-l-zinc-500 pl-6 font-spatial text-xl font-normal"
              )}
            >
              Love cooking
            </div>
          </div>
          <div
            className={clsx(
              "col-span-8 flex h-full items-center justify-end space-x-4 pr-12 print:hidden"
            )}
          >
            <AnimatePresence>
              {isHeroScrolledPast && (
                <motion.div
                  className={clsx("flex-1 border-r pr-4", {
                    "border-r-zinc-500": theme === "dark",
                    "border-r-zinc-200": theme === "light",
                  })}
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 0 }}
                  transition={{ duration: 0.15, ease: "easeOut" }}
                >
                  <_SearchBar
                    handleFocus={handleFocus}
                    inputId={"desktop-search-input"}
                    theme={theme}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <div className={clsx("flex space-x-4")}>
              {(isFetching || isLoggedOut) && <AuthControl theme={theme} />}
              {isLoggedOut && (
                <_JoinCTA context="desktop" currency={currency} theme={theme} />
              )}
              {isLoggedIn && (
                <>
                  <div className="flex gap-2">
                    <div>
                      <AuthMenu />
                    </div>
                    <div>
                      {!isMobPlus && (
                        <Link
                          href={getUpgradeUrl(currency)}
                          onClick={() =>
                            posthog.capture("cta_clicked", { cta: "upgrade" })
                          }
                        >
                          <div
                            className={clsx(
                              "relative z-10 flex h-full items-center whitespace-nowrap rounded-full px-4 font-body text-sm font-medium transition ease-out",
                              "bg-rust text-white"
                            )}
                          >
                            Upgrade
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "col-span-12 hidden h-full items-center justify-start lg:flex"
          )}
        >
          {isClient ? (
            <NavBarDesktop
              primaryNavigation={primaryNavigation}
              onNavHover={(isHovering) => setIsNavBarDesktopOpen(isHovering)}
            />
          ) : null}
        </div>
        <div
          className={clsx("hidden pl-12 lg:block", {
            invisible: isNavBarDesktopOpen,
          })}
        >
          {!isHomepage && <Breadcrumbs items={breadcrumbs} />}
        </div>
      </>
    );
  }
);

const _Mobile = observer(
  ({ currency, theme, primaryNavigation, navigation }) => {
    const { isLoggedOut, isLoggedIn, currentUser } = useCurrentUser();
    const { isOpen, setIsOpen } = useMenuContext();

    const isMobPlus = currentUser?.plan === 2;

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, []);

    return (
      <>
        <div className={clsx("flex h-16 flex-wrap lg:hidden")}>
          <div
            className={clsx("flex h-16 w-1/3 items-center justify-start px-6")}
          >
            <Link
              href="/"
              className={clsx("flex h-auto w-24 items-center active:scale-95")}
              aria-label="Mob"
            >
              <MobLogotype />
            </Link>
          </div>

          <div
            className={clsx("flex h-16 w-2/3 items-center justify-end px-6")}
          >
            {isLoggedOut && (
              <div className={clsx("flex space-x-2")}>
                <_JoinCTA context="mobile" currency={currency} />
              </div>
            )}

            {isLoggedIn && (
              <>
                <div className="flex gap-2">
                  <div>
                    <AuthMenu />
                  </div>
                  {!isMobPlus && (
                    <div>
                      <Link
                        href={getUpgradeUrl(currency)}
                        onClick={() =>
                          posthog.capture("cta_clicked", { cta: "upgrade" })
                        }
                      >
                        <div
                          className={clsx(
                            "relative z-10 flex h-full items-center whitespace-nowrap rounded-full px-4 font-body text-sm font-medium transition ease-out",
                            "bg-rust text-white"
                          )}
                        >
                          Upgrade
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}

            <div>
              <MenuToggle />
            </div>
          </div>
        </div>

        {isClient ? (
          <NavBarMobile
            primaryNavigation={primaryNavigation}
            theme={theme}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            navigation={navigation}
          />
        ) : (
          <NavBarDesktop primaryNavigation={primaryNavigation} />
        )}
      </>
    );
  }
);

const _MobileSearchBar = observer(({ theme = "dark", breadcrumbs }) => {
  const { setIsMobileOverlayOpen } = useSearchContext();
  const isHomepage = useIsHomepage();

  const handleFocus = () => {
    // If we're at /search, don't open the overlay
    if (isSearchIndex) return;

    setIsMobileOverlayOpen(!isSearchIndex);

    // Send an event to GTM
    pushToDataLayer(searchBarFocused);
  };

  const { isHeroScrolledPast } = useHeaderContext();

  const router = useRouter();

  // Check if we're on the search page
  const isSearchIndex = router.pathname === "/search";

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || !isHeroScrolledPast) {
    return null;
  }

  return (
    <>
      <div className={clsx("flex", "lg:hidden")}>
        <div
          className={clsx("flex-1 pb-3", {
            "pl-6 pr-4": isSearchIndex,
            "px-6": !isSearchIndex,
          })}
        >
          <_SearchBar
            handleFocus={handleFocus}
            inputId={"mobile-search-input"}
            theme={theme}
          />
        </div>
        {isSearchIndex && (
          <div
            className={clsx("h-component border-l pl-4 pr-6", {
              "border-l-zinc-700": theme === "dark",
              "border-l-zinc-200": theme === "light",
            })}
          >
            <SearchMobileFilterToggle />
          </div>
        )}
      </div>
      {!isHomepage && (
        <div className="pb-2 pl-6 lg:hidden">
          <div className={clsx("")}>
            <Breadcrumbs items={breadcrumbs} />
          </div>
        </div>
      )}
    </>
  );
});

const _SearchBar = observer(({ handleFocus, inputId, theme = "dark" }) => {
  const router = useRouter();

  const { isLoggedIn, currentUser } = useCurrentUser();

  // Live search is available for logged in users, on the search page
  const searchAsYouType =
    router.pathname !== "/search" && isLoggedIn && currentUser.plan === 2;

  return (
    <SearchBar
      className={clsx("min-w-3xl")}
      searchAsYouType={searchAsYouType}
      onFocus={handleFocus}
      inputId={inputId}
      onSubmit={(query) => pushToDataLayer(searchBarQuerySubmitted, { query })}
      theme={theme}
      inputClassName={clsx("bg-zinc-100")}
    />
  );
});

const _JoinCTA = ({ context = "desktop", currency, theme = "dark" }) => {
  return (
    <Link
      href={`/signup${currency.length ? `?displayCurrency=${currency}` : ""}`}
      className={clsx("Header__joinCta", "h-component")}
      onClick={() => {
        pushToDataLayer(joinButtonClicked);
        posthog.capture("cta_clicked", { cta: "join_the_mob" });
      }}
    >
      <div
        className={clsx(
          "relative z-10 flex h-full items-center whitespace-nowrap rounded-full px-4 font-body text-sm font-medium transition ease-out",
          "bg-acid text-zinc-800 hover:bg-zinc-50 hover:text-zinc-950"
        )}
      >
        Join the mob
      </div>
    </Link>
  );
};

export default Header;
